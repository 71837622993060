import React, { useEffect, useState } from 'react';
import '../style/EventDetail.css';
import CustomCarousel from '../components/Event/ImageSlider';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const EventDetail = () => {
  const param = useParams();
  const eventId = param.id;
  const [event, setEvent] = useState(null);
  const [comments, setComments] = useState(null);

  const [downLoadModal, setDownLoadModal] = useState(false);

  const fetchEvent = async () => {
    try {
      const response = await fetch(`https://syncso.com/api/v1/public/event/${eventId}`)
      const data = await response.json();
      console.log(data);
      setEvent(data);
    } catch (error) {
      console.error("err", error);
    }
  }

  const fetchComments = async () => {
    try {
      const response = await fetch(`https://syncso.com/api/v1/public/comments/list/${eventId}`)
      const data = await response.json();
      console.log(data);
      setComments(data);
    } catch (error) {
      console.error("err", error);
    }
  }

  useEffect(() => {
    fetchEvent();
    fetchComments();
  }, [eventId]);

  const mockImages = [
    require('../assets/img/contact-1.png').default,
    require('../assets/img/contact-2.png').default,
    require('../assets/img/contact-3.png').default,
  ];

  // const comments = [
  //   {
  //     "avatar": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.e920f9d354bf446d9cec1c7fcd3bc2b4_image.jpg",
  //     "childComments": null,
  //     "comment": "Hi",
  //     "commentCount": 1,
  //     "comments": null,
  //     "content": "Hi",
  //     "createdOn": "2024-11-24T03:09:55.257+00:00",
  //     "id": "comment.09aba2dc76fa4f7f935a4d57cbe4cd19",
  //     "likedByCurrentUser": false,
  //     "likes": 0,
  //     "replyTo": {
  //       "avatar": null,
  //       "username": null
  //     },
  //     "replyToUserId": "662065d9002cdc3815f6ec4c",
  //     "replyToUserProfile": {
  //       "backgroundUrl": null,
  //       "displayName": "a",
  //       "firstName": "gary",
  //       "followersCount": 23,
  //       "lastName": "wang",
  //       "photoUrl": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.e920f9d354bf446d9cec1c7fcd3bc2b4_image.jpg",
  //       "tags": null,
  //       "userId": "662065d9002cdc3815f6ec4c",
  //       "userProfile": null
  //     },
  //     "reportCount": 0,
  //     "resourceId": "event.1f68401cc68a4691a8bfccaaf92acf7e",
  //     "revisionNumber": 1,
  //     "userId": "662065d9002cdc3815f6ec4c",
  //     "userProfile": {
  //       "displayName": "a",
  //       "photoUrl": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.e920f9d354bf446d9cec1c7fcd3bc2b4_image.jpg"
  //     },
  //     "username": "a"
  //   },
  //   {
  //     "avatar": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.e920f9d354bf446d9cec1c7fcd3bc2b4_image.jpg",
  //     "childComments": null,
  //     "comment": "Comment",
  //     "commentCount": 0,
  //     "comments": null,
  //     "content": "Comment",
  //     "createdOn": "2024-11-24T03:18:41.622+00:00",
  //     "id": "comment.fab81e488b5240479667ac734ccc3dce",
  //     "likedByCurrentUser": false,
  //     "likes": 0,
  //     "replyTo": {
  //       "avatar": null,
  //       "username": null
  //     },
  //     "replyToUserId": "662065d9002cdc3815f6ec4c",
  //     "replyToUserProfile": {
  //       "backgroundUrl": null,
  //       "displayName": "a",
  //       "firstName": "gary",
  //       "followersCount": 23,
  //       "lastName": "wang",
  //       "photoUrl": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.e920f9d354bf446d9cec1c7fcd3bc2b4_image.jpg",
  //       "tags": null,
  //       "userId": "662065d9002cdc3815f6ec4c",
  //       "userProfile": null
  //     },
  //     "reportCount": 0,
  //     "resourceId": "event.1f68401cc68a4691a8bfccaaf92acf7e",
  //     "revisionNumber": 0,
  //     "userId": "662065d9002cdc3815f6ec4c",
  //     "userProfile": {
  //       "displayName": "a",
  //       "photoUrl": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.e920f9d354bf446d9cec1c7fcd3bc2b4_image.jpg"
  //     },
  //     "username": "a"
  //   }
  // ]

  const formatCommentTime = (createdOn) => {
    const now = moment();
    const commentTime = moment(createdOn);
    const diffInSeconds = now.diff(commentTime, 'seconds');
    const diffInMinutes = now.diff(commentTime, 'minutes');
    const diffInHours = now.diff(commentTime, 'hours');
    const diffInDays = now.diff(commentTime, 'days');

    // Less than 1 minute
    if (diffInSeconds < 60) {
      return 'just now';
    }

    // Same day
    if (now.isSame(commentTime, 'day')) {
      if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
      }
      return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    }

    // Yesterday
    if (now.clone().subtract(1, 'day').isSame(commentTime, 'day')) {
      return `Yesterday ${commentTime.format('HH:mm')}`;
    }

    // Within the last 7 days
    if (diffInDays < 7) {
      return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
    }

    // Older than 7 days
    return commentTime.format('MM-DD');
  };


  // const event = {
  //   "allowComments": false,
  //   "attendants": [
  //     {
  //       "createdBy": null,
  //       "createdOn": "2024-11-24T02:30:20.213+00:00",
  //       "displayName": null,
  //       "email": "garywangop@gmail.com",
  //       "eventId": "event.1f68401cc68a4691a8bfccaaf92acf7e",
  //       "host": true,
  //       "id": "6741f10d4a1a2222c12e1a7f",
  //       "phoneNumber": "1234567890",
  //       "photoUrl": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.e920f9d354bf446d9cec1c7fcd3bc2b4_image.jpg",
  //       "profilePhoto": null,
  //       "updatedBy": null,
  //       "updatedOn": null,
  //       "userId": "662065d9002cdc3815f6ec4c",
  //       "visibility": "PRIVATE"
  //     }
  //   ],
  //   "autoAddFriends": false,
  //   "canRepost": null,
  //   "commentCount": 0,
  //   "createGroupChat": false,
  //   "createdOn": "2024-11-23T15:13:17.884+00:00",
  //   "creatorDob": null,
  //   "creatorId": "662065d9002cdc3815f6ec4c",
  //   "description": "Picture test",
  //   "endDate": null,
  //   "endTime": null,
  //   "estimateCost": null,
  //   "eventCategory": null,
  //   "eventLikeUsers": null,
  //   "eventRequestStatus": null,
  //   "eventStatus": null,
  //   "eventVisibility": "PUBLIC",
  //   "icon": null,
  //   "id": "event.1f68401cc68a4691a8bfccaaf92acf7e",
  //   "likeUsers": null,
  //   "likedByCurrentUser": false,
  //   "likedByUser": false,
  //   "likes": null,
  //   "location": null,
  //   "locationPoint": null,
  //   "maxAttendants": null,
  //   "modifiedOn": null,
  //   "notificationSent": false,
  //   "notify": false,
  //   "originalEventId": null,
  //   "remainingSpots": null,
  //   "reportCount": 0,
  //   "repostMessage": null,
  //   "savedByCurrentUser": false,
  //   "siteName": null,
  //   "startDate": null,
  //   "startTime": null,
  //   "tag": null,
  //   "tags": null,
  //   "title": "Picture test",
  //   "topComment": null,
  //   "userPhotos": [
  //     "https://joyn-event-pictures.s3.us-west-2.amazonaws.com/file.312539731cad4cfebfa2f9ffe3f25651_photo.jpg"
  //   ],
  //   "userResponse": {
  //     "backgroundUrl": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.2aece3a38ddd4e7f8ad0f4d49c05148f_image.jpg",
  //     "displayName": "a",
  //     "firstName": "gary",
  //     "followersCount": 23,
  //     "lastName": "wang",
  //     "photoUrl": "https://joyn-user-avatar.s3.us-west-2.amazonaws.com/file.e920f9d354bf446d9cec1c7fcd3bc2b4_image.jpg",
  //     "tags": null,
  //     "userId": "662065d9002cdc3815f6ec4c",
  //     "userProfile": {
  //       "activityMetaData": [],
  //       "albumPhotos": null,
  //       "applicationCount": 3,
  //       "company": "Example Company",
  //       "createdOn": "2024-05-26T06:24:23.941+00:00",
  //       "creatorId": "661eedcf338e123695683fef",
  //       "educations": [],
  //       "email": "example@example.com",
  //       "experiences": [],
  //       "favoriteCount": 30,
  //       "followerCount": 23,
  //       "followingCount": 12,
  //       "interestedCategory": null,
  //       "karma": 100,
  //       "likeCount": 34,
  //       "linkedinUrl": "https://linkedin.com/in/example",
  //       "locationPoint": null,
  //       "phoneNumber": "1234567890",
  //       "postCount": 10,
  //       "profilePhoto": null,
  //       "profileTags": null,
  //       "referralCount": 2,
  //       "resumeLink": "https://example.com/resume.pdf",
  //       "website": "https://example.com"
  //     }
  //   },
  //   "views": 0,
  //   "webLink": null
  // }

  const dateToMonthDayString = (date) => {
    return date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
  }

  const dateToTimeZoneString = (date, endTime) => {
    let option = {
      hour: 'numeric',
      minute: 'numeric',
    }
    if (endTime) {
      option.timeZoneName = 'short'
    }
    return new Intl.DateTimeFormat('en-US', option).format(date)
  }

  const formatLocation = (event, userId) => {
    if (event?.location) {
      if (event.creatorId === userId || event?.eventRequestStatus === 'accepted') {
        const shorterLocation = event.location.address.split(', ').slice(-3).slice(0, 2).join(', ')
        return shorterLocation
      } else {
        // in case of manual input location
        console.log(event.location)
        if (event.location.latitude === null && event.location.longitude === null) {
          return event.location.address
        }
        return event.location.locality
      }
    } else {
      return ''
    }
  }


  const startDate = event?.startDate
    ? dateToMonthDayString(new Date(event.startDate))
    : ''

  const startTime = event?.startTime
    ? dateToTimeZoneString(
      new Date(event.startTime),
      event.endTime === null
    )
    : ''

  const endTime = event?.endTime
    ? dateToTimeZoneString(new Date(event.endTime), event?.endTime)
    : ''

  const formatTime = endTime ? startTime + "-" + endTime : startTime
  const firstLineTime = (startDate && startTime) ? startDate + ", " + formatTime : startDate ? startDate : formatTime;

  const maxAttendants = event?.maxAttendants
    ? `${event.maxAttendants} Max`
    : null
  const joinedNum = `${event?.attendants?.length || 1} Joined`
  const maxAndJoined = [joinedNum, maxAttendants].filter(Boolean).join(', ')

  const isLocationOnline = event?.location?.address === 'online'

  const closeModal = () => {
    setDownLoadModal(false);
  }

  const openModal = () => {
    setDownLoadModal(true);
  }

  const fetchTurboLink = async () => {
      try {
        const reqBody = {
          "project_id": "ct6os1n5ar9ja3ohgc80",
          "title": "syncso sharing link",
          "link_data": [
            {
              "key": "eventId",
              "value": `${eventId}`
            }
          ],
          "redirections": {
            "redirect_default": "https://syncso.com",
            "redirect_ios": "https://www.apple.com/app-store/"
          }      
        }
        const response = await fetch(
          'https://www.allapp.link/v2/deeplink/create',
          {
            method: 'POST',
            headers: {
              'Authorization' : `Bearer ${'4e7d3d03b8083eb54cfc788185d7166d'}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody),
          }
        )
        const data = await response.json();
        // check if the response is successful
        if (data.code === 200) {
          // redirect to the app
          // window.location.href = data.data.link;

          // new tab
          window.open(data.data.link, '_blank');
        }
      } catch (error) {
        console.error("err", error);
      }
    }

  const requestTurboLink = () => {
    fetchTurboLink();
  }

  return (
    <div className="event-post">
      {/* Left Section: Image Carousel */}
      <CustomCarousel autoPlay={false}>
        {(
          event?.userPhotos || mockImages
        ).map((img, index) => (
          <img key={index} src={img} alt={`Event ${index}`} />
        ))}
      </CustomCarousel>

      {/* Right Section: Event Details */}
      <div className="event-post__details-section">
        <h2 className="event-post__title">
          {event?.title || "Event Title"}
        </h2>
        <div className="event-post__author">
          <img src={
            event?.userResponse?.photoUrl || "https://picsum.photos/200"
          } alt="Unknown User" className='event-post__author-avatar' />
          <div className="event-post__author-info">
            <div className="event-post__author-name">
              {event?.userResponse?.displayName || "Unknown User"}
            </div>
            <div className="event-post__author-followers">
              {event?.userResponse?.followersCount || 0} {event?.userResponse?.followersCount < 2 ? "Follower" : "Followers"}
            </div>
          </div>
        </div>
        <p className="event-post__description">
          {event?.description || "Event Description"}
        </p>
        {/* <p className="event-post__tags">#BadmintonInNYC #BadmintonInNYC</p> */}

        {/* Event Details */}
        <div className="event-post__info">
          {/* <p>📅 Sat, Jun 24, 9:30–11:00 pm</p> */}
          {
            (event?.startDate || event?.startTime) && (
              <p>📅 {firstLineTime}</p>
            )
          }
          {
            event?.location?.address && (
              <p>📍 {isLocationOnline ? 'Online' : formatLocation(event, "")}</p>
            )
          }
          {/* <p>👥 10 Joined, 25 Max</p> */}
          <p>👥 {maxAndJoined} </p>
        </div>

        {/* Action Button */}
        <button className="event-post__join-button" onClick={openModal}>
          Join
        </button>

        {/* Comments Section */}
        <div className="event-post__comments">
          <div className="comment_input">
            <img src={
              "https://picsum.photos/200"
            } alt="Unknown User"
              className='comment_input_avatar' />
            <div className="comment_input_bar">
              <input type="text" placeholder="Write a comment..." className='comment_input_text' />
              <button className='comment_input_button'>&#x21a5;</button>
            </div>
          </div>
          <div className="comment-list">
            {comments &&
              comments.Comments?.map((comment, index) => (
                <div key={comment.id + index} className="comment-main-container">
                  {/* Top-Level Comment */}
                  <div className="comment-container">
                    <div
                      className="avatar-wrapper"
                      onClick={openModal}
                    >
                      <img
                        src={comment.userProfile?.photoUrl || ""}
                        alt="User Avatar"
                        className="avatar"
                      />
                    </div>
                    <div className="comment-content-wrapper">
                      <div className="comment-content">
                        <p className="username">{comment.userProfile?.displayName || "Unknown User"}</p>
                        <div
                          className="reply-button"
                          onClick={openModal}
                        >
                          <span>{comment.comment} {" "}</span>
                          <span className="comment-time">
                            {" "}
                            {formatCommentTime(comment.createdOn)}
                          </span>
                        </div>
                      </div>
                      <div
                        className="like-button"
                        onClick={openModal}
                      >
                        <div className="comment-like">
                          {comment.likedByCurrentUser ? (
                            // <FontAwesomeIcon
                            //   icon={solidHeart}
                            //   color="#FF5A25"
                            //   size="1x"
                            // />
                            <p>❤</p>
                          ) : (
                            <p>🤍</p>
                          )}
                          <span className="like-count">
                            {comment.likes > 0 ? comment.likes : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Sub-Comments */}
                  <div className="comment-reply-container">
                    {comment.comments?.map((reply) => {
                      const user = comments.Users.find((user) => user.userId === reply.userId);
                      const replyToUser = comments.Users.find((user) => user.userId === reply.replyToUserId);
                      return (
                        <div key={reply.id} className="reply-container">
                          <div
                            className="avatar-wrapper"
                            onClick={openModal}
                          >
                            <img
                              src={user?.photoUrl || ""}
                              alt="Reply Avatar"
                              className="reply-avatar"
                            />
                          </div>
                          <div className="comment-content-wrapper">
                            <div className="comment-content">
                              <div
                                onClick={openModal}
                              >
                                <p className="username">
                                  {user?.displayName || "Unknown User"}
                                </p>
                                {comment.id !== reply.resourceId && (
                                  <span className="reply-to">reply to </span>
                                )}
                                {comment.id !== reply.resourceId && (
                                  <span className="reply-to-user">
                                    {replyToUser?.displayName ||
                                      "Unknown User"}{" "}
                                  </span>
                                )}
                                <span>{reply.comment}</span>
                                <span className="comment-time">
                                  {" "}
                                  {formatCommentTime(reply.createdOn)}
                                </span>
                              </div>
                            </div>
                            <div
                              className="like-button"
                              onClick={openModal}
                            >
                              <div className="comment-like">
                                {reply.likedByCurrentUser ? (
                                  <p>❤</p>
                                ) : (
                                  <p>🤍</p>
                                )}
                                <span className="like-count">
                                  {reply.likes > 0 ? reply.likes : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>


      {
        // download app modal
        downLoadModal && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              {/* <span className="close-btn" onClick={closeModal}>
              &times;
            </span> */}
              <h2>SyncSo</h2>
              <p>
                Please download the mobile app to continue
              </p>
              <div className="download-buttons">
                {/* <a
                href="https://apps.apple.com/"
                className="btn app-store"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download on App Store
              </a> */}
                {/* <a
                href="https://play.google.com/store"
                className="btn play-store"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a> */}
                <div
                  className="btn app-store"
                  onClick={requestTurboLink}
                >
                  View in App
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default EventDetail;
